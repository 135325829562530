/* istanbul ignore file */
import { AxiosRequestConfig } from 'axios'
import client from '../axios'
import { getAuthToken, getUrl } from '../utils'
import FileMapper from '@web/mapper/FileMapper'
import HeaderMapper from '@web/mapper/HeaderMapper'

export default class File {
  static uploadWeb (file, config = {}) {
    const formData: FormData = new FormData()
    formData.append('file', file)

    const _config: AxiosRequestConfig = {
      ...config,
      method: 'post',
      url: '/files',
      data: formData,
      config: {
        headers: { 'Content-Type': 'multipart/form-data' }
      },
      headers: { 'Content-Type': 'multipart/form-data' }
    } as AxiosRequestConfig

    return client(_config)
      .then((response) => {
        return {
          data: FileMapper.toClient(response.data),
          headers: HeaderMapper.toClient(response.headers)
        }
      })
  }

  static uploadMobile (file) {
    const options = new window.FileUploadOptions()
    const ft = new window.FileTransfer()

    options.headers = {
      Authorization: getAuthToken(),
      'User-Agent': navigator.userAgent,
      'Accept-Language': navigator.language
    }

    return new Promise((resolve, reject) => {
      ft.upload(file.internalURL, getUrl('/files'), resolve, reject, options, true)
    })
  }

  static upload (file, config = {}) {
    return this.uploadWeb(file, config)
  }
}
