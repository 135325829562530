import Axios from 'axios'
import Token from '@web/common/token'
import { BASE_URL } from '@web/api/utils'

const axios = Axios.create({
  baseURL: BASE_URL
})

/* istanbul ignore next */
const addAuthToken = (config) => {
  const TOKEN = Token.get()
  if (TOKEN) {
    config.headers.common.Authorization = `Bearer ${TOKEN}`
  }
  return config
}

// подготовка запроса
axios.interceptors.request.use(addAuthToken, e => Promise.reject(e))

export default axios
